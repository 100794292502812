<script>
import VueApexCharts from 'vue-apexcharts';
export default {
  name: 'TUTJ',
  props: {
    pieData: {
      type: Object,
    },
  },
  components: {
    VueApexCharts
  },

  data() {
    return {
    }
  }

}
</script>

<template>
  <VueApexCharts style="text-align: left;" type="pie" :options="pieData.options"
    :series="pieData.series"></VueApexCharts>
</template>