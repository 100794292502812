<script>
export default {
  name: 'ItemCard',
  props: {
    itemObject: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      itemData: this.itemObject,
      window: 0,
    }
  }

}
</script>

<template>
  <v-window v-model="window" show-arrows>
    <v-window-item v-for="(itemData, idx) in itemObject" :key="idx">
      <v-card class="pa-2" dark color="transparent">
        <v-card-title>Item breakdown</v-card-title>
        <v-list color="#1C355E">
          <v-list-item class="text-left align-self-start" v-for="(itemProperty, itemKey) in itemData" :key="itemKey">
            <v-list-item-title class="text-wrap">
              <span>{{ itemKey }}</span>: <span>{{ itemProperty }}</span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
    </v-window-item>
  </v-window>
</template>

<style scoped>
.text-wrap {
  word-wrap: break-word;
  white-space: normal;
}
</style>