<template>
    <v-container>
        <v-row>
            <v-card dark flat color="transparent">
                <v-card-title>
                    Lead time statistic
                </v-card-title>
            </v-card>
        </v-row>
        <v-row>
            <v-col>
                <v-card dark color="transparent" flat ref="form">
                    <v-card-text>
                        <v-autocomplete ref="itemno" v-model="itemno" :items="getItemNos" label="Item no"
                            placeholder="Item no"></v-autocomplete>
                        <v-autocomplete ref="equipment" v-model="equipment" :items="getEquipmentTypes" label="Equipment"
                            placeholder="Equipment, e.g., Drill Pipe">
                        </v-autocomplete>
                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox v-model="dates" multiple chips small-chips label="Date range" readonly
                                    v-bind="attrs" v-on="on"></v-combobox>
                            </template>
                            <v-date-picker v-model="dates" range multiple no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(dates)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text @click="ResetForm">
                            Reset
                        </v-btn>
                        <v-btn text @click="submit">
                            Compute
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-card v-if="show_stats" dark flat color="transparent">
                    <v-data-table hide-default-footer :items="this.tableItems"
                        :headers="this.tableHeaders" class="leadtime-data-table"></v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
};
import { mapGetters } from "vuex";
var moment = require('moment')
moment().format()
export default {
    name: "LeadtimeStatistics",
    data() {
        return {
            tableItems: [],
            tableHeaders: [
                {
                    text: 'Leadtime',
                    value: 'title',
                },
                {
                    text: 'Average (days)',
                    value: 'Average',
                },
                {
                    text: 'Median (days)',
                    value: 'Median',
                },
            ],
            leadtimes: ["Inspection", "Hardbanding", "Machining", "Completion"],
            itemnos: null,
            asset: "",
            itemno: "",
            equipment: "",
            ODP_data: { Tubular: [], BHA: [] },
            dates: [],
            menu: false,
            show_stats: false,
            n_total_orders: 0,
            n_total_quantity: 0,
            insp_lead: 0,
            job_lead: 0,
            hardbanding_lead: 0,
            machining_lead: 0,
            jc_median: 0,
            ic_median: 0,
            hc_median: 0,
            mc_median: 0,
            equipmentAbbreviation: {
                'Drill Pipe': 'DP',
                'Drill Collar': 'DC',
                'Heavy Weight': 'HW',
                'Landing String': 'LS',
                'NM Drill Collar': 'NMDC',
                'NM Flex Wate': 'NMFW',
            },
        };
    },
    computed: {
        ...mapGetters(["getUsername", "getAsset", "getFleet"]),
        getItemNos() {
            const itemnolist = new Set()
            for (let i = 0; i < this.ODP_data["Tubular"].length; i++) {
                if (this.ODP_data["Tubular"][i]["Scope_Of_Work"] === "Rig Return" && this.ODP_data["Tubular"][i]["Item_No"] !== "") {
                    itemnolist.add(this.ODP_data["Tubular"][i]["Item_No"])
                }
            }
            var dataList = Array.from(itemnolist);
            if (this.equipment !== "") {
                dataList = dataList.filter(item => {
                    return item.includes(this.equipmentAbbreviation[this.equipment]);
                });
            }
            dataList.sort();

            return dataList;
        },
        getEquipmentTypes() {
            const equipmentTypesList = new Set()
            for (let i = 0; i < this.ODP_data["Tubular"].length; i++) {
                if (this.ODP_data["Tubular"][i]["Scope_Of_Work"] === "Rig Return" && this.ODP_data["Tubular"][i]["Equipment"] !== "") {
                    equipmentTypesList.add(this.ODP_data["Tubular"][i]["Equipment"])
                }
            }
            return Array.from(equipmentTypesList)
        },
        filterItemNosBasedOnEquipment() {
            if (this.equipment !== "") {

            }
        }
    },
    mounted() {
        this.$initialDataLoad({
            BHA: '',
            Tubular: '/getOrderconfirmTubular'
        },
            { username: this.$store.getters.getUsername },
            'Return_Date'
        )
    },
    methods: {
        getItemNos2() {
            const itemnolist = new Set()
            for (let i = 0; i < this.ODP_data["Tubular"].length; i++) {
                if (this.ODP_data["Tubular"][i]["Scope_Of_Work"] === "Rig Return") {
                    itemnolist.add(this.ODP_data["Tubular"][i]["Item_No"])
                }
            }
            return Array.from(itemnolist)
        },
        getEquipmentTypes2() {
            const equipmentTypesList = new Set()
            for (let i = 0; i < this.ODP_data["Tubular"].length; i++) {
                if (this.ODP_data["Tubular"][i]["Scope_Of_Work"] === "Rig Return") {
                    equipmentTypesList.add(this.ODP_data["Tubular"][i]["Equipment"])
                }
            }
            return Array.from(equipmentTypesList)
        },
        getNumberOfDaysWorked(date1, date2) {
            // CHeck if the two dates are the same
            if (date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate()) {
                return 1
            }
            var weekend_days = (date2.getWeek() - date1.getWeek()) * 2
            if (date2.getFullYear() !== date1.getFullYear()) {
                var weeks = 52 - date1.getWeek() // in case of year change, get number of weeks left in that year
                if (date2.getFullYear() - date1.getFullYear() > 1) {
                    weeks = weeks + 52 * (date2.getFullYear() - date1.getFullYear() - 1)
                }
                weeks = weeks + date2.getWeek() + 1 // and add # weeks that have gone in the new year. E.g., if it was week 50 date1, and week 2 date2, then we should get 52-50 + 2 = 4 weeks.
                weekend_days = weeks * 2
            }
            // console.log("Days", (Math.abs(date2 - date1) / (1000*60*60*24)), " weeks", weeks, " WE days", weekend_days)
            var days = (Math.abs(date2 - date1) / (1000 * 60 * 60 * 24)) - weekend_days - 1
            if (date2.getDay() === 0) {
                days = days - 2
            } else if (date2.getDay() === 6) {
                days = days - 1
            }
            if (days < 1) {
                days = 1
            }
            if (date2.getDay() !== 0 || date2.getDay() !== 6) {
                days = days + 1
            }
            return days
        },
        submit() {
            var data = []
            Object.assign(data, this.ODP_data.Tubular)
            var medians = {
                list_of_job_completed_days: [],
                list_of_inspection_completed_days: [],
                list_of_machining_completed_days: [],
                list_of_hardbanding_completed_days: [],

            }
            var n_jc = 0
            var jc_days = 0
            var n_ic = 0
            var ic_days = 0
            var n_hc = 0
            var hc_days = 0
            var n_mc = 0
            var mc_days = 0
            this.n_total_quantity = 0
            this.n_total_orders = 0
            data = data.filter(order => {
                return order['Scope_Of_Work'] === 'Rig Return' || order['Scope_Of_Work'] === 'Inspection'
            })
            var start1 = new Date(["01", "01", "2020"]) // If customer did not choose date, set artificial start and end.
            var end1 = new Date(["01", "01", "2070"])
            if (this.dates.length > 1) {
                var date1 = this.dates[0].split("-")
                var date2 = this.dates[1].split("-")
                if (date1 < date2) {
                    start1 = new Date(date1)
                    end1 = new Date(date2)
                } else {
                    start1 = new Date(date2)
                    end1 = new Date(date1)
                }
            }
            if (this.itemno !== "") {
                data = data.filter(order => {
                    return order["Item_No"] === this.itemno
                })
            }
            if (this.equipment !== "") {
                data = data.filter(order => {
                    return order["Equipment"] === this.equipment
                })
            }
            // if (this.$store.getters.getFleet !== true) {
            //     data = data.filter(order => {
            //         return order["Asset"] === this.$store.getters.getAsset
            //     })
            // }
            for (let i = 0; i < data.length; i++) {
                var return_date_ms = data[i]["Return_Date"] * 1000 // date in milliseconds since 1.1.1970
                var return_date = new Date(return_date_ms)
                if (return_date >= start1 && return_date <= end1 && data[i]['QTY_Recived'] >= 10) {
                    var job_completed = new Date(data[i]["Job_Completed_Date"].split("/"))
                    var insp_completed = new Date(data[i]['Inspection_Completed_DATE'])
                    var hc_completed = new Date(data[i]['Hardbaning_Completed_Date'])
                    var mc_completed = new Date(data[i]['Machinig_Completed_Date'])
                    if (!isNaN(insp_completed.getTime())) {
                        const days_taken_for_current_order = Math.round(this.getNumberOfDaysWorked(return_date, insp_completed))
                        if (days_taken_for_current_order <= 50) {
                            medians.list_of_inspection_completed_days.push(days_taken_for_current_order)
                            ic_days = ic_days + days_taken_for_current_order
                            n_ic = n_ic + 1
                            this.n_total_orders = this.n_total_orders + 1
                            this.n_total_quantity = this.n_total_quantity + parseInt(data[i]['QTY_Recived'])
                        }
                    }
                    if (!isNaN(job_completed.getTime())) {
                        const days_taken_for_current_order = Math.ceil(this.getNumberOfDaysWorked(return_date, job_completed))
                        if (days_taken_for_current_order <= 50) {
                            medians.list_of_job_completed_days.push(days_taken_for_current_order)
                            jc_days = jc_days + days_taken_for_current_order
                            n_jc = n_jc + 1
                        }
                    }
                    if (!isNaN(hc_completed.getTime())) {
                        const days_taken_for_current_order = Math.ceil(this.getNumberOfDaysWorked(insp_completed, hc_completed))
                        if (days_taken_for_current_order <= 50) {
                            medians.list_of_hardbanding_completed_days.push(days_taken_for_current_order)
                            hc_days = hc_days + days_taken_for_current_order
                            n_hc = n_hc + 1
                        }
                    }
                    if (!isNaN(mc_completed.getTime())) {
                        const days_taken_for_current_order = Math.ceil(this.getNumberOfDaysWorked(insp_completed, mc_completed))
                        if (days_taken_for_current_order <= 50) {
                            medians.list_of_machining_completed_days.push(days_taken_for_current_order)
                            mc_days = mc_days + days_taken_for_current_order
                            n_mc = n_mc + 1
                        }
                    }
                } else {
                    continue;
                }
            }
            for (var [key, _] of Object.entries(medians)) {
                medians[key].sort(function (a, b) { return a - b });
            }
            this.jc_median = medians.list_of_job_completed_days[Math.floor(medians.list_of_job_completed_days.length / 2)]
            this.ic_median = medians.list_of_inspection_completed_days[Math.floor(medians.list_of_inspection_completed_days.length / 2)]
            this.mc_median = medians.list_of_machining_completed_days[Math.floor(medians.list_of_machining_completed_days.length / 2)]
            this.hc_median = medians.list_of_hardbanding_completed_days[Math.floor(medians.list_of_hardbanding_completed_days.length / 2)]
            this.show_stats = true
            this.insp_lead = Math.floor(ic_days / (n_ic === 0 ? n_ic + 1 : n_ic))
            this.job_lead = Math.floor(jc_days / (n_jc === 0 ? n_jc + 1 : n_jc))
            this.hardbanding_lead = Math.floor(hc_days / (n_hc === 0 ? n_hc + 1 : n_hc))
            this.machining_lead = Math.floor(mc_days / (n_mc === 0 ? n_mc + 1 : n_mc))
            this.tableItems = [
                { title: 'Inspection', Average: this.insp_lead, Median: this.ic_median },
                { title: 'Machining', Average: this.machining_lead, Median: this.mc_median },
                { title: 'Hardbanding', Average: this.hardbanding_lead, Median: this.hc_median },
                { title: 'Completion', Average: this.job_lead, Median: this.jc_median },
            ]

        },
        ResetForm() {
            this.dates = []
            this.itemno = "",
                this.equipment = ""
            this.show_stats = false
            // this.getItemNos2()
            // this.getEquipmentTypes2()
        }
    }
}
Date.prototype.getWeek = function () {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}
</script>

<style scoped>
.theme--dark.v-data-table {
    background-color: transparent;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
    color: white;
    font-size: 16px !important;
    /* padding: 8px 0px 8px 0px; */
    height: 72px;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px !important;
    height: 64px;
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid #ffffff; 
}

::v-deep .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row){
    border-bottom: thin solid #ffffff !important;
}

::v-deep .theme--dark.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: #ffffff;
}
</style>
